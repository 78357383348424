import React from "react"
import classNames from "classnames"
import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"
import { HomeProjectMp4Card } from "../HomeProjectMp4Card"
import { HomeProjectImageCard } from "../HomeProjectImageCard"
import { HomeProjectTextCard } from "../HomeProjectTextCard"
import { HomeProjectMediaCard } from "../HomeProjectMediaCard"

import * as styles from "./HomeProjectsOneColumn.module.scss"

const HomeProjectsOneColumn = ({ cards }) => {
  const isDesktop = useMediaQuery(MediaQuery.Desktop)

  return (
    <>
      {cards?.map((card, index) => {
        return (
          <div
            key={`project–${card.name}`}
            className={classNames(styles.cardBlockItem, {
              [styles.projectBlockItemBoxed]: card?.type === "boxed",
            })}
          >
            {/* Media Card */}
            {card?.type === "media" && <HomeProjectMediaCard card={card} />}
            {/* Mp4 Card */}
            {card?.type === "mp4" && (
              <HomeProjectMp4Card card={card} isPortrait={card.isMp4Portrait} />
            )}
            {/* Image Card */}
            {card?.type === "image" && <HomeProjectImageCard card={card} />}
            {/* Text Card */}
            {card?.type === "text" && <HomeProjectTextCard card={card} />}
          </div>
        )
      })}
    </>
  )
}

export { HomeProjectsOneColumn }
